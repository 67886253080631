
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import QuizAttempt from '@/modules/courseDetail/api/quizAttempt'
  import getApi from '@/api'
  import { QuestionInterface, QuestionType } from '@/interface/BaseInterface'
  import { namespace } from 'vuex-class'
  import { findIndex } from 'lodash'
  import BaseEditor from '@/components/base/editor/BaseEditor.vue'

  const quizAttempt: QuizAttempt = getApi('quizAttempt')
  const tQuizResult = namespace('tQuizResult')

  @Component({ components: { BaseEditor } })
  export default class QuestionResultComment extends Vue {
    @Prop({ required: true, type: Object }) question!: QuestionInterface
    @Prop({ required: true, type: Object }) data!: Record<string, any>

    @tQuizResult.Action('addCommentAttempt') addCommentAttempt!: (data: { _id: string; comment: string; grade?: number }) => void

    questionType = QuestionType

    comment = ''
    grade = 0

    isEdit = false
    isLoading = false

    get isShowGrade() {
      return (
        findIndex([QuestionType.ESSAY, QuestionType.FILE_UPLOAD], value => {
          return value === this.question.type
        }) !== -1
      )
    }

    handleEdit() {
      this.isEdit = true
      this.comment = this.data.comment
      this.grade = this.data.grade
    }

    async handleComment() {
      try {
        if (this.isLoading) {
          return
        }
        this.isLoading = true
        await quizAttempt.attemptComment(
          this.quizId || this.data.quizId,
          this.attemptId || this.data.attemptId,
          this.question._id,
          this.comment,
          this.isShowGrade ? this.grade : undefined
        )
        this.addCommentAttempt({ _id: this.data._id, comment: this.comment, grade: this.isShowGrade ? this.grade : undefined })
        this.comment = ''
        this.grade = 0
        this.isEdit = false
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }

    handleChange(comment: string) {
      this.comment = comment
    }

    mounted() {
      if (this.checkRole(['review'], 'COURSE_DETAIL')) {
        this.isEdit = this.data.comment === '' && (!this.data.grade || this.data.grade === 0)
      } else {
        this.isEdit = false
      }

      // this.isEdit = false
    }
  }
