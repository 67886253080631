
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuizInterface, PartInterface, QuestionType, QuestionInterface } from '@/interface/BaseInterface'
  import QuizApi from '../../api/quizApi'
  import getApi from '@/api'
  import QuestionResult from '../../../questions/components/question/QuestionResult.vue'
  import { forEach, size } from 'lodash'

  const quizApi: QuizApi = getApi('quiz')

  @Component({
    components: { QuestionResult }
  })
  export default class QuizTestResult extends Vue {
    @Prop({ required: true, type: Object }) quiz!: QuizInterface
    @Prop({ required: false, type: Boolean, default: false }) openComment!: boolean

    parts: PartInterface[] = []

    selectPart = 0
    selectIndex = 1
    totalPart = 0
    // attempt: UserAnswerInterface[] = []

    async init() {
      const result = await quizApi.getQuestion(this.quizId || this.quiz._id)
      this.parts = result.parts
      this.totalPart = this.parts.length - 1
      forEach(this.parts, value => {
        value.numQuestion = this.getTotalQuestion(value.questions)
      })
      // this.attempt = await quizAttempt.attemptDetail(this.quizId, this.attemptId)
    }

    getTotalQuestion(questions: QuestionInterface[] | undefined) {
      if (!questions) {
        return 0
      }
      let total = 0
      forEach(questions, value => {
        if (value.type === QuestionType.TEXT_ONLY) {
          total = total + this.getTotalQuestion(value.childQuestions)
        } else {
          const a = size(value.keys)
          total = total + (a === 0 ? 1 : a)
        }
      })
      return total
    }

    getIndex(part: number, index: number) {
      // if (part === 0) {
      //   return index + 1
      // }
      // let num = index + 1
      // forEach(this.parts, (value, key) => {
      //   if (key < part) {
      //     num = num + (value.numQuestion || 0)
      //   }
      // })
      // return num
      let num = 0
      let questions: QuestionInterface[] = []

      forEach(this.parts, (value, key) => {
        if (key < part) {
          num = num + (value.numQuestion || 0)
        }
        if (key === part) {
          questions = value.questions || []
        }
      })

      forEach(questions, (value, key) => {
        if (index <= key) {
          return false
        }

        if (value.type === QuestionType.TEXT_ONLY) {
          num = num + this.getTotalQuestion(value.childQuestions)
        } else {
          const a = size(value.keys)
          num = num + (a === 0 ? 1 : a)
        }
      })

      return num + 1
    }

    getMyAswer(question: QuestionInterface) {
      console.log(question)
    }

    created() {
      this.init()
    }
  }
