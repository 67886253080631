import { render, staticRenderFns } from "./QuizTestResult.vue?vue&type=template&id=00ac4779&scoped=true&"
import script from "./QuizTestResult.vue?vue&type=script&lang=ts&"
export * from "./QuizTestResult.vue?vue&type=script&lang=ts&"
import style0 from "./QuizTestResult.vue?vue&type=style&index=0&id=00ac4779&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00ac4779",
  null
  
)

export default component.exports