
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuestionInterface, QuestionType, UserAnswerInterface } from '@/interface/BaseInterface'
  import CalculatedQuestion from './type/CalculatedQuestion.vue'
  import EssayQuestion from './type/EssayQuestion.vue'
  import TrueFalse from './type/TrueFalse.vue'
  import FileUploadQuestion from './type/FileUploadQuestion.vue'
  import MatchingQuestion from './type/MatchingQuestion.vue'
  import MultipleAnswers from './type/MultipleAnswers.vue'
  import MultipleBlanks from './type/MultipleBlanks.vue'
  import MultipleDropdowns from './type/MultipleDropdowns.vue'
  import MutipleChoice from './type/MutipleChoice.vue'
  import NumericalQuestion from './type/NumericalQuestion.vue'
  import ShortAnswer from './type/ShortAnswer.vue'
  import TextOnlyQuestionResult from './type/TextOnlyQuestionResult.vue'
  import QuestionResultComment from './QuestionResultComment.vue'
  import { findIndex, forEach, size, find, groupBy, filter } from 'lodash'
  import { namespace } from 'vuex-class'

  const tQuizResult = namespace('tQuizResult')

  @Component({
    components: {
      CalculatedQuestion,
      EssayQuestion,
      TrueFalse,
      FileUploadQuestion,
      MatchingQuestion,
      MultipleAnswers,
      MultipleBlanks,
      MultipleDropdowns,
      MutipleChoice,
      NumericalQuestion,
      ShortAnswer,
      TextOnlyQuestionResult,
      QuestionResultComment
    }
  })
  export default class QuestionResult extends Vue {
    @Prop({ required: true, type: Object }) data!: QuestionInterface
    @Prop({ required: false, type: Number }) index!: number
    @Prop({ required: false, type: Boolean, default: true }) openComment!: boolean

    @tQuizResult.State('attemptResult') attemptResult!: UserAnswerInterface[]

    questionType = QuestionType

    get answerComponent() {
      const component: Record<QuestionType, any> = {
        multiple_choice_question: 'MutipleChoice',
        true_false_question: 'TrueFalse',
        short_answer_question: 'ShortAnswer',
        fill_in_multiple_blanks_question: 'MultipleBlanks',
        multiple_answers_question: 'MultipleAnswers',
        multiple_dropdowns_question: 'MultipleDropdowns',
        // matching_question: 'MatchingQuestion',
        // numerical_question: 'NumericalQuestion',
        // calculated_question: 'CalculatedQuestion',
        essay_question: 'EssayQuestion',
        file_upload_question: 'FileUploadQuestion',
        text_only_question: 'TextOnlyQuestionResult'
      }
      return component[this.data.type] || null
    }

    get getAttempt() {
      const a = find(this.attemptResult, value => {
        return value.questionId === this.data._id
      })
      return (
        a || {
          grade: 0,
          quizId: '',
          userId: '',
          comment: '',
          _id: ''
        }
      )
    }

    get getMyAnswer() {
      const rightResult = filter(this.data.answers, value => {
        if (value.type === QuestionType.MUTILCHOICE || value.type === QuestionType.TRUE_FALSE) {
          return value.isCorrect
        } else {
          return true
        }
      })
      let result: Record<string, any> = {
        myAnswer: [],
        rightResult: this.isShowQuestion ? rightResult : groupBy(rightResult, 'key'),
        isCorrect: false,
        comment: '',
        _id: '',
        grade: 0
      }

      // my answer
      const a = find(this.attemptResult, value => {
        return value.questionId === this.data._id
      })
      if (a) {
        let userAnswer: any | Record<string, any> = null
        if (typeof a.userAnswer === 'string') {
          const b = find(this.data.answers, value => {
            return value._id === a.userAnswer
          })
          userAnswer = b ? [b.content] : [a.userAnswer]
        } else {
          userAnswer = []
          // console.log(typeof a.userAnswer)
          if (typeof a.userAnswer === 'object') {
            userAnswer = {}
            forEach(a.userAnswer, (value, key) => {
              if (this.data.type === this.questionType.MUTILPLE_ANSWERS) {
                const b = find(this.data.answers, elm => {
                  return elm._id === value
                })
                userAnswer[key] = b?.content || value
              } else {
                userAnswer[key] = [value]
              }
            })
          } else {
            forEach(a.userAnswer, value => {
              const c = find(this.data.answers, elm => {
                return elm._id === value
              })
              userAnswer.push(c ? c.content : value)
            })
          }
        }
        result = {
          myAnswer: userAnswer,
          rightResult: this.isShowQuestion ? a.rightAnswer : groupBy(typeof a.rightAnswer === 'string' ? [a.rightAnswer] : a.rightAnswer, 'key'),
          isCorrect: a.isCorrect,
          comment: a.comment,
          _id: a._id,
          grade: a.grade
        }
      }
      return result
    }

    get isShowQuestion() {
      return (
        findIndex([QuestionType.FILL_IN_MULTIPLE, QuestionType.MUTILPLE_DROPDOWN, QuestionType.ESSAY, QuestionType.TEXT_ONLY, QuestionType.FILE_UPLOAD], value => {
          return value === this.data.type
        }) === -1
      )
    }

    get isShowAnswer() {
      return (
        findIndex([QuestionType.TEXT_ONLY], value => {
          return value === this.data.type
        }) === -1
      )
    }

    get isOnlyShowResult() {
      return (
        findIndex([QuestionType.ESSAY, QuestionType.FILE_UPLOAD], value => {
          return value === this.data.type
        }) !== -1
      )
    }

    get numKey() {
      return this.data.type === QuestionType.TEXT_ONLY ? this.getTotalQuestion(this.data.childQuestions) : size(this.data.keys)
    }

    getTotalQuestion(questions: QuestionInterface[] | undefined) {
      if (!questions) {
        return 0
      }
      let total = 0
      forEach(questions, value => {
        if (value.type === QuestionType.TEXT_ONLY) {
          total = total + this.getTotalQuestion(value.childQuestions)
        } else {
          const a = size(value.keys)
          total = total + (a === 0 ? 1 : a)
        }
      })
      return total
    }

    handleGetAnswer(data: any) {
      console.log(data)
    }

    created() {
      forEach(this.data.answers, value => {
        value.questionId = this.data._id
      })
    }
  }
