
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuestionInterface, QuestionType } from '@/interface/BaseInterface'
  import { forEach, size } from 'lodash'

  @Component({
    components: {
      ChildQuestionView: () => import('../QuestionResult.vue')
    }
  })
  export default class TextOnlyQuestion extends Vue {
    @Prop({ required: true, type: Object }) data!: QuestionInterface
    @Prop({ required: true, type: Number }) index!: number

    getTotalQuestion(questions: QuestionInterface[] | undefined) {
      if (!questions) {
        return 0
      }
      let total = 0
      forEach(questions, value => {
        if (value.type === QuestionType.TEXT_ONLY) {
          total = total + this.getTotalQuestion(value.childQuestions)
        } else {
          const a = size(value.keys)
          total = total + (a === 0 ? 1 : a)
        }
      })
      return total
    }

    getIndex(key: number) {
      let total = this.index
      forEach(this.data.childQuestions, (value, key_) => {
        if (key === key_) {
          return false
        }
        if (value.type === QuestionType.TEXT_ONLY) {
          total = total + this.getTotalQuestion(value.childQuestions)
        } else {
          const a = size(value.keys)
          total = total + (a === 0 ? 1 : a)
        }
      })
      return total
    }
  }
